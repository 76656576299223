@import "variables";

@mixin for-phone-landscape-up {
  @media (min-width: 650px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}

////////////
//FLEXBOX//
//////////
@mixin flex($dir, $jc, $ai, $gap: 0) {
  display: flex;
  flex-direction: $dir;
  justify-content: $jc;
  align-items: $ai;
  gap: $gap;
}

@mixin boxshadow($x: 0, $y: 5px, $blur: 15px, $radius: 0) {
  box-shadow: rgba(0, 0, 0, 0.35) $x $y $blur $radius;
}

///////////
//BUTTON//
/////////
@mixin btn($width, $radius: 20, $height: 35) {
  font-family: $strict-font;
  background-color: $red;
  color: white;
  border-radius: $radius;
  width: $width;
  height: $height;
  text-align: center;
  text-decoration: none;
  border: none;
  cursor: pointer;
}
