@import url("https://use.typekit.net/elq2whh.css");
@import url("https://fonts.googleapis.com/css2?family=Gwendolyn&family=Playfair+Display:wght@400;800&display=swap");
@import url("https://use.typekit.net/elq2whh.css");
@import url("https://fonts.googleapis.com/css2?family=Gwendolyn&family=Playfair+Display:wght@400;800&display=swap");
.headerContainer {
  width: 100%;
  margin: 30px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0;
}
.headerContainer .logoContainer {
  margin-left: 40px;
}
.headerContainer .logoContainer .logo {
  display: none;
}
@media (min-width: 650px) {
  .headerContainer .logoContainer .logo {
    display: flex;
    width: 240px;
  }
}
.headerContainer .logoContainer .smalllogo {
  display: flex;
  width: 100px;
}
@media (min-width: 650px) {
  .headerContainer .logoContainer .smalllogo {
    display: none;
  }
}
.headerContainer nav {
  display: flex;
  list-style: none;
  margin-right: 35px;
}
.headerContainer nav .menuItem {
  font-family: futura-pt, sans-serif;
  color: #9c5026;
  margin: 5px;
  font-size: 22px;
  text-decoration: none;
  display: inline-block;
  padding: 5px 15px;
  position: relative;
}
.headerContainer nav .menuItem:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 0.5px;
  left: 50%;
  position: absolute;
  background: #9c5026;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.headerContainer nav .menuItem:hover:after {
  width: 100%;
  left: 0;
}
.headerContainer nav .active:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 0.5px;
  left: 0;
  position: absolute;
  background: #9c5026;
  width: 100%;
}

footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 35vh;
}
footer div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0;
  background-color: #ede5d7;
  height: 60%;
  width: 75%;
  padding-top: 30px;
  padding-bottom: 20px;
}
footer .icons {
  font-size: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
footer .icons a {
  color: black;
}
footer .icons a :hover {
  color: #9c5026;
  cursor: pointer;
}
footer h3 {
  padding-top: 30px;
  font-family: abril-fatface, serif;
  letter-spacing: 2px;
  font-size: 30pt;
  margin: 0;
}
footer p {
  padding-bottom: 30px;
  font-family: futura-pt, sans-serif;
  font-size: 10px;
  bottom: 0;
}

.homeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0;
  background-color: #99a27b;
  width: 100%;
}
.homeContainer .description {
  width: 50%;
  text-align: center;
  font-size: 16px;
  margin-bottom: 50px;
}
.homeContainer .description a {
  color: #9c5026;
  text-decoration: none;
  font-family: abril-fatface, serif;
}
.homeContainer .description a:hover {
  color: black;
}

.projectPage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0;
  background-color: #bf7616;
  width: 100%;
}
.projectPage .projectPageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0;
  background-color: #ede5d7;
  width: 70%;
  margin: 40px 0;
}
.projectPage .projectsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0;
  flex-wrap: wrap;
  margin: 20px;
}
.projectPage .projectsContainer .singleProjectContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0;
}
.projectPage .projectsContainer .singleProjectContainer img {
  width: 250px;
}

.aboutContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0;
  background-color: #9c5026;
  height: 100%;
  width: 100%;
}
.aboutContainer .textContainer {
  width: 70%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.aboutContainer .textContainer .newsPaper {
  font-family: abril-fatface, serif;
  font-size: 22px;
  display: inline;
}
.aboutContainer .textContainer .skillsContainer {
  width: 80%;
  display: flex;
  margin-bottom: 30px;
  flex-wrap: wrap;
  justify-content: center;
}
.aboutContainer .textContainer .skillsContainer p {
  padding: 0 5px;
  margin: 0;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0;
  width: 100%;
  font-family: futura-pt, sans-serif;
  width: 100%;
}
main .title {
  font-family: abril-fatface, serif;
  font-size: 46px;
  text-align: center;
}

.imgContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0;
  margin-top: 10px;
}
.imgContainer img {
  width: 300px;
}
.imgContainer div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0;
  font-family: abril-fatface, serif;
}
.imgContainer a {
  text-decoration: none;
  color: black;
}
.imgContainer a:hover {
  color: #9c5026;
}

.notFound {
  background-color: #9c5026;
  width: 100%;
  text-align: center;
  font-family: futura-pt, sans-serif;
  font-size: 30px;
  margin: 20px 0;
}

.appContainer header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: white;
  margin: 0 60px 50px 60px;
  padding: 10px 0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 3px;
}
.appContainer header h1 {
  font-family: "Gwendolyn", cursive;
  font-size: 30pt;
  margin: 10px;
}
@media (min-width: 650px) {
  .appContainer header h1 {
    font-size: 40pt;
  }
}
@media (min-width: 768px) {
  .appContainer header h1 {
    font-size: 45pt;
  }
}
.appContainer header .bird {
  width: 60px;
  margin-bottom: -15px;
}
@media (min-width: 768px) {
  .appContainer header .bird {
    width: 80px;
  }
}
.appContainer main {
  width: 100%;
  display: flex;
  justify-content: center;
}
.appContainer main .homeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  padding: 30px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 3px;
  background-color: white;
}
.appContainer main .homeContainer div {
  margin: 10px;
}
.appContainer main .homeContainer .img {
  width: 250px;
  border-radius: 3px;
}
@media (min-width: 768px) {
  .appContainer main .homeContainer .img {
    width: 400px;
  }
}
.appContainer main .homeContainer h3 {
  margin: 0px;
  font-size: 16pt;
}
.appContainer h1,
.appContainer h2,
.appContainer h3 {
  font-family: "Gwendolyn", cursive;
}
.appContainer h4,
.appContainer h5 {
  font-size: 800;
  font-family: "Playfair Display", serif;
}
.appContainer p {
  font-family: "Playfair Display", serif;
}
.appContainer .imgContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.appContainer .imgContainer img {
  height: 280px;
  margin-top: 40px;
  object-fit: cover;
  border-radius: 3px;
}
@media (min-width: 650px) {
  .appContainer .imgContainer img {
    object-fit: cover;
    height: 500px;
  }
}
.appContainer .imgContainer .arrows {
  display: flex;
  flex-direction: row;
}
.appContainer .imgContainer .arrows .arrow {
  color: grey;
  opacity: 70%;
  padding: 10px;
  font-size: 30pt;
}
.appContainer .imgContainer .arrows .arrow :hover {
  cursor: pointer;
  color: #5b5a5a;
}

html {
  scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0;
  background-color: #ede5d7;
}

