.projectPage {
  @include mixins.flex(row, center, center, 0);
  background-color: $orange;
  width: 100%;

  .projectPageContainer {
    @include mixins.flex(column, center, center, 0);
    background-color: $white;
    width: 70%;
    margin: 40px 0;
  }

  .projectsContainer {
    @include mixins.flex(row, center, center, 0);
    flex-wrap: wrap;
    margin: 20px;
    .singleProjectContainer {
      @include mixins.flex(column, flex-start, center, 0);
      img {
        width: 250px;
      }
    }
  }
}
