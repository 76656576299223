@import url("https://use.typekit.net/elq2whh.css");

// Fonts
$abril: abril-fatface, serif;

$futura: futura-pt, sans-serif;

// Colors
$white: #ede5d7;
$green: #99a27b;
$red: #9c5026;
$orange: #bf7616;

@import url("https://fonts.googleapis.com/css2?family=Gwendolyn&family=Playfair+Display:wght@400;800&display=swap");

// Fonts

$S: "Playfair Display", serif;
$C: "Gwendolyn", cursive;

// Colors
$whiteAnette: #f0eadc;
