// Mixins
@use "mixins";
@import "variables";

// Styling elements
@import "./elements/header";
@import "./elements/footer";
@import "./elements/home";
@import "./elements/projects";
@import "./elements/about";
@import "./elements/main";

html {
  scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0;
  background-color: $white;
}
