.aboutContainer {
  @include mixins.flex(column, center, center, 0);
  background-color: $red;
  height: 100%;
  width: 100%;

  .textContainer {
    width: 70%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .newsPaper {
      font-family: $abril;
      font-size: 22px;
      display: inline;
    }

    .skillsContainer {
      width: 80%;
      display: flex;
      margin-bottom: 30px;
      flex-wrap: wrap;
      justify-content: center;

      p {
        padding: 0 5px;
        margin: 0;
      }
    }
  }
}
