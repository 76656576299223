.homeContainer {
  @include mixins.flex(column, center, center, 0);
  background-color: $green;
  width: 100%;
  .description {
    width: 50%;
    text-align: center;
    font-size: 16px;
    margin-bottom: 50px;
    a {
      color: $red;
      text-decoration: none;
      font-family: $abril;
    }
    a:hover {
      color: black;
    }
  }
}
