.headerContainer {
  width: 100%;
  margin: 30px 10px;
  @include mixins.flex(row, space-between, center);

  .logoContainer {
    margin-left: 40px;

    .logo {
      display: none;
      @include mixins.for-phone-landscape-up {
        display: flex;
        width: 240px;
      }
    }
    .smalllogo {
      display: flex;
      width: 100px;
      @include mixins.for-phone-landscape-up {
        display: none;
      }
    }
  }
  nav {
    display: flex;
    list-style: none;
    margin-right: 35px;

    .menuItem {
      font-family: $futura;
      color: $red;
      margin: 5px;
      font-size: 22px;
      text-decoration: none;
      display: inline-block;
      padding: 5px 15px;
      position: relative;
      //text-transform: lowercase;
    }

    .menuItem:after {
      background: none repeat scroll 0 0 transparent;
      bottom: 0;
      content: "";
      display: block;
      height: 0.5px;
      left: 50%;
      position: absolute;
      background: $red;
      transition: width 0.3s ease 0s, left 0.3s ease 0s;
      width: 0;
    }
    .menuItem:hover:after {
      width: 100%;
      left: 0;
    }

    .active:after {
      background: none repeat scroll 0 0 transparent;
      bottom: 0;
      content: "";
      display: block;
      height: 0.5px;
      left: 0;
      position: absolute;
      background: $red;
      width: 100%;
    }
  }
}
