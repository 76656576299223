footer {
  @include mixins.flex(column, center, center, 0);

  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 35vh;

  div {
    @include mixins.flex(column, center, center, 0);
    background-color: $white;
    height: 60%;
    width: 75%;
    padding-top: 30px;
    padding-bottom: 20px;
  }
  .icons {
    font-size: 40px;
    @include mixins.flex(row, center, center, 30px);
    a {
      color: black;

      :hover {
        color: $red;
        cursor: pointer;
      }
    }
  }
  h3 {
    padding-top: 30px;
    font-family: $abril;
    letter-spacing: 2px;
    font-size: 30pt;
    margin: 0;
  }

  p {
    padding-bottom: 30px;
    font-family: $futura;
    font-size: 10px;
    bottom: 0;
  }
}
