main {
  @include mixins.flex(column, center, center, 0);
  width: 100%;
  font-family: $futura;
  width: 100%;

  .title {
    font-family: $abril;
    font-size: 46px;
    text-align: center;
  }
}

.imgContainer {
  @include mixins.flex(row, center, center, 0);
  margin-top: 10px;
  img {
    width: 300px;
  }
  div {
    @include mixins.flex(column, center, center, 0);
    font-family: $abril;
  }
  a {
    text-decoration: none;
    color: black;
  }
  a:hover {
    color: $red;
  }
}

.notFound {
  background-color: $red;
  width: 100%;
  text-align: center;
  font-family: $futura;
  font-size: 30px;

  margin: 20px 0;
}

.appContainer {
  header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: white;
    margin: 0 60px 50px 60px;
    padding: 10px 0;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 3px;
    h1 {
      font-family: $C;
      font-size: 30pt;
      margin: 10px;

      @include mixins.for-phone-landscape-up {
        font-size: 40pt;
      }
      @include mixins.for-tablet-landscape-up {
        font-size: 45pt;
      }
    }
    .bird {
      width: 60px;
      margin-bottom: -15px;
      @include mixins.for-tablet-landscape-up {
        width: 80px;
      }
    }
  }

  main {
    width: 100%;
    display: flex;
    justify-content: center;

    .homeContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 60%;
      padding: 30px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      border-radius: 3px;
      background-color: white;

      div {
        margin: 10px;
      }
      .img {
        width: 250px;
        border-radius: 3px;
        @include mixins.for-tablet-landscape-up {
          width: 400px;
        }
      }
      h3 {
        margin: 0px;
        font-size: 16pt;
      }
    }
  }

  h1,
  h2,
  h3 {
    font-family: $C;
  }

  h4,
  h5 {
    font-size: 800;
    font-family: $S;
  }
  p {
    font-family: $S;
  }

  .imgContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      height: 280px;
      margin-top: 40px;
      object-fit: cover;
      border-radius: 3px;
      @include mixins.for-phone-landscape-up {
        object-fit: cover;
        height: 500px;
      }
    }

    .arrows {
      display: flex;
      flex-direction: row;
      .arrow {
        color: grey;
        opacity: 70%;
        padding: 10px;
        font-size: 30pt;
        :hover {
          cursor: pointer;
          color: rgb(91, 90, 90);
        }
      }
    }
  }
}
